
function ComoEmpezar() {
    return (
        <section id="comoEmpezar" className="bg-[#DFE0DF] pb-16 grid justify-items-center lg:px-24">
            <div className="mx-auto max-w-4xl text-center py-24">
                <h2 className="mt-2 text-3xl font-bold tracking-tight text-[#0085ca] sm:text-4xl">
                    Estas a 4 simples pasos de crear tu menú digital
                </h2>
            </div>
            <ol className="px-6">
                <li class="border-l-2 border-dashed border-[#0085ca20]">
                    <div class="md:flex flex-start">
                        <div class="bg-[#f7630c] w-6 h-6 flex items-center justify-center rounded-xl -ml-3.5">
                            <p className="text-black" >1</p>
                        </div>
                        <div class="block pl-6 max-w-md ml-6 mb-6">
                            <div class="flex justify-between mb-4">
                                <a href="https://www.app.pidelacarta.com/registration" target="_blank" rel="noreferrer" 
                                class="text-xl font-medium text-[#f7630c] hover:text-[#0085ca]  duration-300 transition ease-in-out text-sm">Registrate</a>
                            </div>
                            <p class="text-black mb-4">Ve a nuestra pagina para registro de usuarios.</p>
                            <a
                                href="https://www.app.pidelacarta.com/registration" target="_blank" rel="noreferrer"
                                className="rounded-md bg-[#f7630c] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#0085ca] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Ir a Registrarme
                            </a>
                        </div>
                    </div>
                </li>
                <li class="border-l-2 border-dashed border-[#0085ca40]">
                    <div class="md:flex flex-start">
                        <div class="bg-[#f7630c] w-6 h-6 flex items-center justify-center rounded-xl -ml-3.5">
                            <p className="text-black" >2</p>
                        </div>
                        <div class="block pl-6 max-w-md ml-6 mb-6">
                            <div class="flex justify-between mb-4">
                                <p class="text-xl font-medium text-[#f7630c] hover:text-[#0085ca] duration-300 transition ease-in-out text-sm">Confirma tu correo eléctronico</p>
                            </div>
                            <p class="text-black mb-4">Ve a la bandeja de entrada del correo que utilizaste para registrarte y haz click en el link de validación.</p>
                        </div>
                    </div>
                </li>
                <li class="border-l-2 border-dashed border-[#0085ca70]">
                    <div class="md:flex flex-start">
                        <div class="bg-[#f7630c] w-6 h-6 flex items-center justify-center rounded-xl -ml-3.5">
                            <p className="text-black" >3</p>
                        </div>
                        <div class="block pl-6 max-w-md ml-6 mb-6">
                        <div class="flex justify-between mb-4">
                                <a href="https://www.app.pidelacarta.com/login" target="_blank" rel="noreferrer"
                                class="text-xl font-medium text-[#f7630c] hover:text-[#0085ca]  duration-300 transition ease-in-out text-sm">Inicia Sesión</a>
                            </div>
                            <p class="text-black mb-4">Ve a nuestra pagina inicio de sesión de usuarios.</p>
                            <a
                                href="https://www.app.pidelacarta.com/login" target="_blank" rel="noreferrer"
                                className="rounded-md bg-[#f7630c] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#0085ca] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Iniciar Sesión
                            </a>
                        </div>
                    </div>
                </li>
                <li class="border-l-2 border-dashed border-[#0085ca]">
                    <div class="md:flex flex-start">
                        <div class="bg-[#f7630c] w-6 h-6 flex items-center justify-center rounded-xl -ml-3.5">
                            <p className="text-black" >4</p>
                        </div>
                        <div class="block pl-6 max-w-md ml-6 mb-6">
                            <div class="flex justify-between mb-4">
                                <p class="text-xl font-medium text-[#f7630c] hover:text-[#0085ca] duration-300 transition ease-in-out text-sm">Registra tu restaurante</p>
                            </div>
                            <p class="text-black mb-4">Ya con sesión iniciada, completa el formularion con informacion de nombre, ubicacion y url para tu negocio.</p>
                        </div>
                    </div>
                </li>
                <li class="border-l-2 border-dashed border-[#0085ca00]">
                    <div class="md:flex flex-start">
                        <div class="w-6 h-14 flex items-center justify-center rounded-xl -ml-3.5">
                            <p className="text-black text-4xl" >🎉</p>
                        </div>
                        <div class="block pl-6 max-w-md ml-6 mb-6">
                            <div class="flex justify-between mb-4">
                                <p class="text-xl font-medium text-[#f7630c] hover:text-[#0085ca]  duration-300 transition ease-in-out text-sm">¡Felicidades!</p>
                            </div>
                            <p class="text-black mb-4">Ya puedes empezar a cargar el menú para tu negocio</p>
                        </div>
                    </div>
                </li>
            </ol>
        </section>
    )
}

export default ComoEmpezar;