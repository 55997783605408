import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
    {
        name: 'Alta disponibilidad',
        description:
            'Tu información está siempre accesible y respaldada en la nube para garantizar su disponibilidad en todo momento.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Menú con certificado de seguridad',
        description:
            'Protegemos la seguridad de tus datos y los de tus clientes con un certificado SSL en pidalacarta.com.',
        icon: LockClosedIcon,
    },
    {
        name: 'En Tiempo Real',
        description:
            'Cada cambio que realices se refleja instantáneamente en tu menú digital. No necesitas esperar autorización, tú tienes el control absoluto.',
        icon: ArrowPathIcon,
    },
    {
        name: 'Panel Administrativo con Autenticación',
        description:
            'Accede de manera segura al panel administrativo utilizando tus credenciales de acceso exclusivas.',
        icon: FingerPrintIcon,
    },

]

function Features({ onNavLinkClick }) {
    return (
        <section id="informacion" className="relative pb-20 bg-[#0085ca] lg:px-24">
            <div className="mx-auto max-w-2xl text-center pt-24 pb-0 lg:pb-16">
                <h2 className="mt-2 text-3xl font-bold tracking-tight text-[#DFE0DF] sm:text-4xl">
                    Lo que te ofrecemos
                </h2>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-center ">
                    <div className="mx-auto max-w-2xl lg:max-w-4xl">
                        <div className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative flex flex-col min-w-0">
                                    <div className="px-4 py-5 flex-auto">
                                        <div key={feature.name} className="relative flex flex-col">
                                            <div className="grid grid-flow-col-dense">
                                                <div
                                                    className="
                                                    text-[#DFE0DF] 
                                                p-3 
                                                text-center 
                                                inline-flex 
                                                items-center 
                                                justify-center 
                                                w-12 
                                                h-12 
                                                mb-5 
                                                shadow-lg 
                                                bg-[#f7630c]
                                                rounded-md
                                                ">
                                                    <feature.icon />
                                                </div>
                                                <div className="px-2 flex-auto">
                                                    <h6 className="text-xl text-[#DFE0DF] mb-1 font-semibold">{feature.name}</h6>
                                                    <p className="mb-4 text-[#DFE0DF]">{feature.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <div class="z-10 w-8/12 md:w-6/12 lg:w-3/12 px-2 md:px-4 mr-auto ml-auto">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg-[#FCE100]">
                            <img alt="pidalacarta-logo" src={logo} className="w-full align-middle border-2 border-[#FCE100] " />
                            <div className="p-8">
                                <h4 className="text-xl font-bold text-black">
                                    Un menú digital en segundos
                                </h4>
                                <p className="text-sm font-light mt-2 text-black">
                                    ¡Registra tu restaurante y carga fácilmente tus categorías, platos y promociones de manera rápida y sencilla!
                                </p>
                                <p className="text-sm font-light mt-2 text-black">
                                    Con Pidelacarta, olvídate de imprimir o marcar precios en tus menús físicos. ¡Solo necesitas imprimir un QR para colocarlo en tus mesas!
                                </p>
                                <p className="text-sm font-light mt-2 text-black">
                                    Además, con nuestra plataforma, tienes la opción de publicar el enlace de tu menú en las redes sociales de tu local. Así, tus clientes podrán visualizar tus platos y promociones con facilidad.
                                </p>
                                <div className="mt-4 flex items-center justify-center">
                                    <a
                                        href="#contacto"
                                        onClick={() => onNavLinkClick("contacto")}
                                        className="hover:bg-black bg-[#f7630c] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
                                    >
                                        Contactanos Hoy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* <div className="w-fit flex">
                <svg className="absolute bottom-0 " viewBox="0 0 1425 205.55" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M0,178.42l23.75-1.64c23.75-1.65,71.25-4.94,118.75-5.59s95,1.23,142.5,2.71,95,2.63,142.5,4.11,95,3.45,142.5,2.88,95-3.54,142.5-8.8,95-12.58,142.5-13.57,95,4.53,142.5,8.23,95,5.67,142.5,4,95-6.91,142.5-9.79,95-3.37,118.75-3.7L1425,157v65.78H0Z" fill="#DFE0DF"></path>
                    </g>
                    <defs>
                        <clipPath id="clip0"><rect width="1425" height="444" fill="white"></rect></clipPath>
                    </defs>
                </svg>
            </div> */}

        </section>
    )
}

export default Features;