
import svg from "../assets/svg/order2.svg"
function Home({ onNavLinkClick }) {

    return (
        <section id="inicio" className="flex items-center justify-center h-screen bg-[#FFCF4C] lg:px-8">
            <div class="flex flex-col md:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6">
                <img src={svg} class=" lg:pl-[2%] lg:pr-[2%] lg:visible w-3/6 col-start-1 row-start-1 self-center" alt="escanear-menu-qr" />
                <div className="col-start-1 row-start-1 mx-auto">
                    <div className="text-center ">
                        <h1 className="text-4xl lg:pl-16 font-bold tracking-tight text-black sm:text-6xl">
                            El <span className="text-[#0085ca] p0 m0" >menú digital</span> ideal <br />para tu negocio
                        </h1>
                        <h2 className="mt-6 lg:pl-16 font-semibold text-lg leading-8 text-[#0085ca]">
                            ¡Dale un impulso a tu negocio! <br />
                        </h2>
                        <h3 className="mt-6 lg:pl-16 font-semibold text-lg leading-8 text-black">
                            Crea un Menú Digital personalizado en minutos, con promociones atractivas, categorías organizadas y platos irresistibles.
                        </h3>
                        <div className="mt-10 lg:pl-16 flex items-center justify-center gap-x-6">
                            <a
                                href="#contacto"
                                onClick={() => onNavLinkClick("contacto")}
                                className="rounded-md bg-[#f7630c] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#0085ca] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Contactanos Hoy
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    )
}


export default Home;