import './App.css';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Features from './components/Features'
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
import ComoEmpezar from './components/ComoEmpezar';

function App() {

  const handleNavLinkClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <NavBar onNavLinkClick={handleNavLinkClick} />
      <Home onNavLinkClick={handleNavLinkClick} />
      <Features onNavLinkClick={handleNavLinkClick} />
      <ComoEmpezar onNavLinkClick={handleNavLinkClick} />
      <ContactUs />
      <Footer onNavLinkClick={handleNavLinkClick} />
    </div>
  );
}

export default App;
