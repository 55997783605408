// import logo  from '../assets/logo_2000x1000.png'

function Footer({ onNavLinkClick }) {

    return (
        <footer className="shadow bg-[#0085ca] lg:px-24">
            <div className="w-full max-w-screen-4xl pt-8">
                <div className="flex items-center justify-center">
                    <ul className="sm:flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-white">
                        <li className="my-2">
                            <a href="#inicio" onClick={() => onNavLinkClick('home')} className="hover:text-[#FFCF4C] me-4 md:me-6 ">Inicio</a>
                        </li>
                        <li className="my-2">
                            <a href='https://www.app.pidelacarta.com/registration' target='_blank' rel="noreferrer" className="hover:text-[#FFCF4C] me-4 md:me-6">Probar 14 Dias</a>
                        </li>
                        <li className="my-2">
                            <a href="#informacion" onClick={() => onNavLinkClick('informacion')} className="hover:text-[#FFCF4C] me-4 md:me-6">¿Qué te ofrecemos?</a>
                        </li>
                        <li className="my-2">
                            <a href="#comoEmpezar" onClick={() => onNavLinkClick('comoEmpezar')} className="hover:text-[#FFCF4C] me-4 md:me-6">¿Cómo empezar?</a>
                        </li>
                        <li className="my-2">
                            <a href='https://www.app.pidelacarta.com/demo' target='_blank' rel="noreferrer" className="hover:text-[#FFCF4C] me-4 md:me-6">Demo</a>
                        </li>
                        <li className="my-2">
                            <a href="#contacto" onClick={() => onNavLinkClick('contacto')} className="hover:text-[#FFCF4C] me-4 md:me-6">Contacto</a>
                        </li>
                    </ul>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-white lg:my-8" />
                <span className="flex items-center justify-center text-sm text-white sm:text-center dark:text-white">© {new Date().getFullYear()} <a href="https://www.pidelacarta.com/" >pidelacarta.com</a>.</span>
            </div>
        </footer>
    );
}
export default Footer;