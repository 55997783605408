import { SocialIcon } from "react-social-icons";
import { PhoneIcon } from '@heroicons/react/24/outline'

const stats = [


    {
        id: 1,
        value:
            <div className="grid justify-items-center" >
                <div className="inline-block align-middle" >
                    <SocialIcon
                        style={{ height: 50, width: 50 }}
                        network="whatsapp" />
                </div>
                <p className="text-black p-4 text-lg font-semibold">Whatsapp</p>
            </div>,
        action:
            <div>
                <a
                    href="https://wa.link/7c8thg"
                    target="_blank"
                    rel="noreferrer"
                    className="relative rounded-md px-4 py-2 text-sm text-white font-semibold bg-[#f7630c] hover:bg-[#0085ca] focus-visible:outline ">
                    Enviar Mensaje
                </a>
            </div>
    },
    {
        id: 2,
        value:
            <div className="grid justify-items-center" >
                <div className="inline-block align-middle">
                    <PhoneIcon
                        style={{ height: 50, width: 50, }}
                        className="fill-white" />
                </div>
                <p className="text-black p-4 text-lg font-semibold">Teléfono</p>
            </div>,
        action:
            <div>
                <span className="rounded-md px-4 py-2 text-sm text-white font-semibold bg-[#f7630c]">
                    +5491123891820
                </span>
            </div>
    },
]

function ContactUs() {
    return (
        <section id="contacto" className="bg-[#FFCF4C] pb-16">

            <div className="mx-auto max-w-4xl text-center py-24">
                <h2 className="mt-2 text-3xl font-bold tracking-tight text-black sm:text-4xl">
                    Contacto
                </h2>
                <p className="mt-6 text-lg text-black">
                    Comunicate con nosotros por los siguientes medios, te responderemos a la brevedad:
                </p>
            </div>
            <div className="sm:pb-64">
                <div className="mx-auto max-w-7xl md:px-24 lg:px-72">
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center md:grid-cols-2">
                        {stats.map((stat) => (
                            <div key={stat.id} className="md:rounded-md p-8 shadow-lg">
                                    {stat.value}
                                    {stat.action}
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </section>
    )
}

export default ContactUs;