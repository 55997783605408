import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo  from '../assets/logo_2x1.png'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function NavBar({ onNavLinkClick }) {
    const navigation = [
        {
            id: 1,
            name: 'Probar 14 dias',
            target: '_blank',
            href: 'https://www.app.pidelacarta.com/registration',
            onClick: () => null,
            current: true
        },
        {
            id: 2,
            name: '¿Qué te ofrecemos?',
            href: '#informacion',
            onClick: () => onNavLinkClick('informacion'),
            current: false
        },
        {
            id: 3,
            name: '¿Cómo empezar?',
            href: '#comoEmpezar',
            onClick: () => onNavLinkClick('comoEmpezar'),
            current: false
        },
        {
            id: 4,
            name: 'Demo',
            target: '_blank',
            href: 'https://www.app.pidelacarta.com/demo',
            onClick: () => null,
            current: false
        },
        {
            id: 5,
            name: 'Contacto',
            href: '#contacto',
            onClick: () => onNavLinkClick('contacto'),
            current: false
        },
    ]
    return (
        <Disclosure as="nav" className="bg-[#FFCF4C] sticky top-0 z-50 ">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-full px-8 sm:px-16 lg:px-24">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-black-400 hover:bg-black-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:justify-between sm:items-stretch ">
                                <div className="flex flex-shrink-0 items-center">
                                    <a className="text-white" href="#inicio" ><img src={logo} alt='pidelacarta' class="h-8 w-auto" /></a>
                                </div>
                                <div className="hidden sm:ml-6 items-center sm:block">
                                    <div className="flex items-center space-x-4">
                                        {navigation.map((item) => (
                                            <a
                                                onClick={item.onClick}
                                                key={item.name}
                                                href={item.href}
                                                target={item.target || ""}
                                                className={classNames( 'text-nowrap  rounded-md transition duration-100',
                                                    'text-black-300 hover:bg-black-700 hover:text-[#0085ca]',
                                                    'px-3 py-2 text-sm font-medium',
                                                    item.id === 1 ? ' bg-[#f7630c] text-slate-50 border-2 border-white border-dotted hover:bg-[#0085ca] hover:text-slate-50 w-fit':''
                                                )}
                                                aria-current={item.current ? 'page' : undefined}
                                            >
                                                {item.name}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="sm:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    onClick={item.onClick}
                                    as="a"
                                    href={item.href}
                                    target={item.target || ""}
                                    className={classNames(
                                        'text-black-300 hover:bg-black-700 hover:text-[#0085ca]',
                                        'block px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>

    );
}

export default NavBar;